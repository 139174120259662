import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiConfigService } from './apiconfig.service';

@Injectable()
export class AuthService {
    apiURL;
    options;

    constructor(private http: HttpClient, private apiconfig: ApiConfigService) { 
        this.apiURL = this.apiconfig.getApiURL();
        this.options = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.apiconfig.getAPIToken()
                }
            ),
            withCredentials: true
        }
    }

    myProfile() {
        return this.http.get(this.apiURL + "/api/v1/auth/me/").pipe(take(1));
    }
}