// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const oauth = {
  clientId : '97bb9f3a-da53-4425-888d-4a4387d89edc',
  redirectURI: 'https://frontend.pskriteriji.dev.mortar.tovarnaidej.com/token',
  resource: 'pskriteriji.dev.mortar.tovarnaidej.com',
  issuer: 'https://sts1.porsche.si/adfs',
  audience: 'microsoft:identityserver:pskriteriji.dev.mortar.tovarnaidej.com"',
  flow: 'code' // code or implicit
}

export const environment = {
  production: false,
  apiURL: "https://pskriteriji.dev.mortar.tovarnaidej.com",
  //loginURL:"http://web.example.com:7100/oauth2/login",
  loginURL: oauth.issuer + "/oauth2/authorize?client_id=" + oauth.clientId + "&response_type=token&redirect_uri=" + encodeURI(oauth.redirectURI) + "&response_mode=fragment&resource="+oauth.resource,
  logoutURL: "https://pskriteriji.dev.mortar.tovarnaidej.com/oauth2/logout"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
