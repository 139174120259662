import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfigService } from '../../services/apiconfig.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { environment, oauth } from '../../../environments/environment';

import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../../helper/authConfig';

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  providers: [ApiConfigService, AuthService, ProfileService],
})
export class ProfileComponent {

  public data;
  private logoutURL = environment.logoutURL;

  constructor(
    private profileService: ProfileService,
    private location: Location,
    private router: Router,
    private oauthService: OAuthService
  ) {
    profileService.getProfile().subscribe(
      (data) => {
        this.data = data;
      }
    );

    this.oauthService.configure(authCodeFlowConfig);
    
    if(oauth.flow == 'code'){
      this.oauthService.setupAutomaticSilentRefresh();
    }
  }

  public logout(){
    this.profileService.clearPermissions();
    this.profileService.clearProfile();
    localStorage.removeItem('jwt');
    localStorage.removeItem('permissions');
    localStorage.removeItem('info');
    
    if(oauth.flow == 'code'){
      this.oauthService.logOut();
    }
    
    window.location.href = this.logoutURL;
  }

  public refresh(){

    localStorage.removeItem('jwt');
    localStorage.removeItem('permissions');
    localStorage.removeItem('info');

    if(oauth.flow == 'code'){
      this.oauthService.refreshToken();
    } else {
      this.router.navigateByUrl('login');
    }
  }
}
