import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-title',
  templateUrl: 'title.component.html'
})
export class TitleComponent {

  constructor(public titleService:TitleService) {

  }


 }
