import { Injectable } from '@angular/core';
import { CriteriaService } from './criteria.service';

import {FlatTreeControl} from '@angular/cdk/tree';
import {DynamicFlatNode, DynamicDataSource} from '../helper/tree-data-source';
@Injectable({
  // declares that this service should be created
  // by the root application injector.
  providedIn: 'root',
})
export class TreeEditService {

  public subbrand;
  public subbrand_text;
  public brand;
  public brand_text;
  public search;

  public treeControl: FlatTreeControl<DynamicFlatNode>;
  public dataSource: DynamicDataSource;

  constructor(private criteriaService: CriteriaService) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.criteriaService, 'si');
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;
}