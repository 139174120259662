import { ContractorService } from '../services/contractor.service';
import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
    constructor(public item: string, public level = 1, public expandable = false, public id: Number, public uniqueId = '',
                public isLoading = false, public index = 0) {}
  }
  
   /**
   * File database, it can build a tree structured Json object from string.
   * Each node in Json object represents a file or a directory. For a file, it has filename and type.
   * For a directory, it has filename and children (a list of files or directories).
   * The input will be a json object string, and the output is a list of `FileNode` with nested
   * structure.
   */
  export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  
    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

    subbrand;
    status;
    partner;
  
    get data(): DynamicFlatNode[] { return this.dataChange.value; }
    set data(value: DynamicFlatNode[]) {
      this._treeControl.dataNodes = value;
      this.dataChange.next(value);
    }
  
    constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
                private _service: ContractorService,
                private lang: string) {}
  
    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
      this._treeControl.expansionModel.changed.subscribe(change => {
        if ((change as SelectionChange<DynamicFlatNode>).added ||
          (change as SelectionChange<DynamicFlatNode>).removed) {
          this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
        }
      });
  
      return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }
  
    disconnect(collectionViewer: CollectionViewer): void {}
  
    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
      if (change.added) {
        change.added.forEach(node => this.toggleNode(node, true));
      }
      if (change.removed) {
        change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
      }
    }
  
    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: DynamicFlatNode, expand: boolean) {
      //const children = this._database.getChildren(node.item);
      const children = [];
      const index = this.data.indexOf(node);
      if (!children || index < 0) { // If no children, or cannot find the node, no op
        return;
      }
  
      node.isLoading = true;
  
      //setTimeout(() => {
        if (expand) {
          let subbrandArr = Array();

          if(this.subbrand){
            subbrandArr.push(this.subbrand);
          }

          this._service.getSubBrands({brand: node.id.toString(), limit: 50}).subscribe(
            (data) => {
              if((data as any).results.length > 0){
                let nodes = (data as any).results.map(brand => new DynamicFlatNode(brand.name, node.level + 1, false, brand.id));
                this.data.splice(index + 1, 0, ...nodes);
                this.addRowIndex();
                this.dataChange.next(this.data);
              }

              // notify the change
              //this.dataChange.next(this.data);
              node.isLoading = false;
            }
          );
  
          /*
          const nodes = children.map(name =>
            //new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name)));
            new DynamicFlatNode(name, node.level + 1, true));
          this.data.splice(index + 1, 0, ...nodes);
          */
        } else {
          let count = 0;
          for (let i = index + 1; i < this.data.length
            && this.data[i].level > node.level; i++, count++) {}
          this.data.splice(index + 1, count);
          
          // notify the change
          this.dataChange.next(this.data);
          node.isLoading = false;
        }
      //}, 100);
    }

    setSubBrand(subbrandID){
      this.subbrand = subbrandID;
    }

    setStatus(status){
      this.status = status;
    }

    setPartner(partnerID){
      this.partner = partnerID;
    }

    addRowIndex(){
      this.data.forEach((val, ind)=> {
        this.data[ind].index = ind;
      });
    }

    refreshBrand(brandID){
      let foundBrand = this.data.filter(obj => {return obj.id === brandID})

      foundBrand.forEach(obj => {
        if(this._treeControl.isExpanded(obj)){
          this._treeControl.collapse(obj);
          this._treeControl.expand(obj);
        }
      })
    }
  }