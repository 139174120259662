import {Component, ViewChild, TemplateRef} from '@angular/core';

import { ProfileService } from '../../services/profile.service';
import { UserGroups } from '../../guards/usergroup.model';
import { navItems } from '../../_nav';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from "moment";

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent{
  minimized = false;
  //public navItems = [...navItems];
  public navItems = [];

  modalRef: BsModalRef;
  @ViewChild('CheckNoticeModal') public checkNoticeModal: TemplateRef<any>;
  @ViewChild('UnknownUserModal') public UnknownUserModal: TemplateRef<any>;
  public noticeConfirmed = false;

  constructor(private profileService: ProfileService, private modalService: BsModalService,){
    this.navItems = navItems.filter((navItem) => {

      const matchedGroups = profileService.info.groups.filter(value => navItem.userGroups.includes(value.name));

      if(matchedGroups.length > 0){
        return true;
      } else {
        return false;
      }

    })
  }

  toggleMinimize(e) {
    this.minimized = e;
  }

  public ngAfterViewInit(){
    let timeAgreed = localStorage.getItem('statsAgreement');

    if(this.profileService.info.groups.find(value => value.name == UserGroups.UNKNOWN)){
      this.openModal(this.UnknownUserModal);
    } else {
      if( !timeAgreed || timeAgreed == 'yes' || moment(timeAgreed.toString()) < moment().subtract(24, 'hours')){
        this.openModal(this.checkNoticeModal);
      }
    }
  }

  public agreeAndClose(){
    localStorage.setItem('statsAgreement', moment().toISOString());
    this.modalRef.hide()
  }

  openModal(template: TemplateRef<any>) {

    let mconfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(template, mconfig);
  }
}
