import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { environment, oauth } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

import { authCodeFlowConfig } from '../../helper/authConfig';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  loginUrl;
  loading;
  show = false;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private oauthService: OAuthService
  ) {
    //profileService.getCachedPermissions();
    //profileService.getCachedProfile();

    this.loginUrl = environment.loginURL;
    this.oauthService.configure(authCodeFlowConfig);
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      //URL params
      let returnUrl = params['returnUrl'];

      this.profileService.getPermissions().subscribe(
        (data) => {
          this.profileService.permissions = (data as any);
          localStorage.setItem('permissions', JSON.stringify(this.profileService.permissions));
        }
      );

      let result = this.profileService.getProfile().subscribe(
        (data) => {
          this.profileService.info = (data as any);
          localStorage.setItem('info', JSON.stringify(this.profileService.info));
          this.router.navigateByUrl(returnUrl);
        },
        (error) => {
          this.show = true;

          if(oauth.flow == 'code'){
            this.oauthService.loadDiscoveryDocumentAndTryLogin();
            this.oauthService.initCodeFlow();
          }
        } 
      );      
    });

  }

 }
