import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ApiConfigService } from './apiconfig.service';
import { Router } from '@angular/router';


interface filterContact {
    contractor: Number,
    type: String,
    type__code: String,
    limit?: Number,
    offset?: Number
}

interface filterBusinessContact {
    brand: Number,
}

interface filterContractGroup {
    contractor: Number,
    limit?: Number,
    offset?: Number
}

interface filterSubContract {
    contract: Number,
    limit?: Number,
    offset?: Number
}

interface filterSubBrand {
    brand: String,
    limit?: Number,
    offset?: Number
}

interface filterServiceType {
    parent: String,
    primary: String
}

@Injectable()
export class ContractorService {
    apiURL;
    options;

    constructor(private http: HttpClient, private apiconfig: ApiConfigService, private router: Router) { 
        this.apiURL = this.apiconfig.getApiURL();
        this.options = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.apiconfig.getAPIToken(),
                'X-CSRFToken': this.apiconfig.getCSRFToken()
                }
            ),
            withCredentials: true
        }
    }

    private addSearchParam(searchText){
        let search = '';

        if(searchText && searchText !== ''){
            search='&search='+searchText;
        }

        return search;
    }

    private filterArrayValues(paramName, arrayValues){
        let filter = '';

        if(arrayValues){
            arrayValues.forEach(element => {
                filter += paramName+"="+element+"&";
            });
        }

        return filter;
    }

    private filterObjectFields(objectValues){
        let filter = '';

        if(objectValues){
            let arrKeys = Object.keys(objectValues);

            arrKeys.forEach(element => {
                filter += element+"="+objectValues[element]+"&";
            });
        }

        return filter;
    }

    getList(filterObj, searchText= '') {

        let search = this.addSearchParam(searchText);

        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/list/?" + filter + search, this.options);
    }

    getListExport(filterObj, searchText= ''): Observable<ArrayBuffer | Blob> {

        let search = this.addSearchParam(searchText);

        let filter = this.filterObjectFields(filterObj);

        let options = this.options
        options.responseType = 'blob'

        return this.http.get(this.apiURL + "/api/v1/contractor/list/export_xlsx/?" + filter + search, options).pipe(take(1));
    }

    getContractorById(contractorID) {
        return this.http.get(this.apiURL + "/api/v1/contractor/list/"+contractorID+"/", this.options).pipe(take(1));
    }

    deactivateContractor(contractorID) {
        return this.http.patch(this.apiURL + "/api/v1/contractor/list/"+contractorID+"/", {"active": false}, this.options).pipe(take(1));
    }

    activateContractor(contractorID) {
        return this.http.patch(this.apiURL + "/api/v1/contractor/list/"+contractorID+"/", {"active": true}, this.options).pipe(take(1));
    }

    createContractor(contractorObj){
        return this.http.post(this.apiURL + "/api/v1/contractor/list/", contractorObj, this.options).pipe(take(1));
    }

    updateContractor(contractorID, contractorObj){
        return this.http.put(this.apiURL + "/api/v1/contractor/list/"+contractorID+"/", contractorObj, this.options).pipe(take(1));
    }

    getContacts(filterObj: filterContact) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/contact/?"+filter, this.options).pipe(take(1));
    }

    createContact(contactObj){
        return this.http.post(this.apiURL + "/api/v1/contractor/contact/", contactObj, this.options).pipe(take(1));
    }

    updateContact(contactID, contactObj){
        return this.http.put(this.apiURL + "/api/v1/contractor/contact/"+contactID+"/", contactObj, this.options).pipe(take(1));
    }

    deleteContact(contactID){
        return this.http.delete(this.apiURL + "/api/v1/contractor/contact/"+contactID+"/", this.options).pipe(take(1));
    }

    getBusinessContacts(filterObj: filterBusinessContact) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/mailing/?"+filter, this.options).pipe(take(1));
    }
    
    createBusinessContact(contactObj){
        return this.http.post(this.apiURL + "/api/v1/contractor/mailing/", contactObj, this.options).pipe(take(1));
    }

    updateBusinessContact(contactID, contactObj){
        return this.http.put(this.apiURL + "/api/v1/contractor/mailing/"+contactID+"/", contactObj, this.options).pipe(take(1));
    }

    deleteBusinessContact(contactID){
        return this.http.delete(this.apiURL + "/api/v1/contractor/mailing/"+contactID+"/", this.options).pipe(take(1));
    }

    getContractGroups(filterObj: filterContractGroup) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/contractgroup/?"+filter, this.options).pipe(take(1));
    }

    getContractGroupById(groupID) {
        return this.http.get(this.apiURL + "/api/v1/contractor/contractgroup/"+groupID+"/", this.options).pipe(take(1));
    }

    createContractGroup(groupObj){
        return this.http.post(this.apiURL + "/api/v1/contractor/contractgroup/", groupObj, this.options).pipe(take(1));
    }

    updateContractGroup(groupID, groupObj){
        return this.http.put(this.apiURL + "/api/v1/contractor/contractgroup/"+groupID+"/", groupObj, this.options).pipe(take(1));
    }

    deactivateContractGroup(groupID) {
        return this.http.patch(this.apiURL + "/api/v1/contractor/contractgroup/"+groupID+"/", {"active": false}, this.options).pipe(take(1));
    }

    activateContractGroup(groupID) {
        return this.http.patch(this.apiURL + "/api/v1/contractor/contractgroup/"+groupID+"/", {"active": true}, this.options).pipe(take(1));
    }

    swapContractGroup(targetID, sourceID) {
        return this.http.post(this.apiURL + "/api/v1/contractor/swap/contractgroup/?source="+sourceID+"&target="+targetID, this.options).pipe(take(1));
    }

    getSubContracts(filterObj: filterSubContract) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/subcontract/service/?"+filter, this.options).pipe(take(1));
    }

    updateSubContract(contractID, contractObj) {

        return this.http.put(this.apiURL + "/api/v1/contractor/subcontract/service/"+contractID+"/", contractObj, this.options).pipe(take(1));
    }

    deleteSubContract(contractID) {

        return this.http.delete(this.apiURL + "/api/v1/contractor/subcontract/service/"+contractID+"/", this.options).pipe(take(1));
    }

    createSubContract(contractObj) {
        
        return this.http.post(this.apiURL + "/api/v1/contractor/subcontract/service/", contractObj, this.options).pipe(take(1));
    }

    getSubContractsSubBrands(filterObj: filterSubContract) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/subcontract/subbrand/?"+filter, this.options).pipe(take(1));
    }

    updateSubContractSubBrands(contractID, contractObj) {

        return this.http.put(this.apiURL + "/api/v1/contractor/subcontract/subbrand/"+contractID+"/", contractObj, this.options).pipe(take(1));
    }

    deleteSubContractSubBrands(contractID) {

        return this.http.delete(this.apiURL + "/api/v1/contractor/subcontract/subbrand/"+contractID+"/", this.options).pipe(take(1));
    }

    createSubContractSubBrands(contractObj) {
        
        return this.http.post(this.apiURL + "/api/v1/contractor/subcontract/subbrand/", contractObj, this.options).pipe(take(1));
    }

    getServiceTypeGroups() {
        return this.http.get(this.apiURL + "/api/v1/contractor/servicetypegroup/", this.options).pipe(take(1));
    }

    getServiceTypes(filterObj: filterServiceType) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/servicetype/?"+filter, this.options).pipe(take(1));
    }

    createServiceType(serviceObj) {

        return this.http.post(this.apiURL + "/api/v1/contractor/servicetype/", serviceObj, this.options).pipe(take(1));
    }

    getContactTypes() {
        return this.http.get(this.apiURL + "/api/v1/contractor/contacttype/?limit=100", this.options).pipe(take(1));
    }

    getBrands() {
        return this.http.get(this.apiURL + "/api/v1/contractor/brand/?limit=100", this.options).pipe(take(1));
    }

    getBrandById(brandID) {
        return this.http.get(this.apiURL + "/api/v1/contractor/brand/"+brandID+"/", this.options).pipe(take(1));
    }

    deleteBrand(brandID) {
        return this.http.delete(this.apiURL + "/api/v1/contractor/brand/"+brandID+"/", this.options).pipe(take(1));
    }

    updateBrand(brandID, brandObj) {
        return this.http.put(this.apiURL + "/api/v1/contractor/brand/"+brandID+"/", brandObj, this.options).pipe(take(1));
    }

    getSubBrands(filterObj:filterSubBrand) {

        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/contractor/subbrand/?"+filter, this.options).pipe(take(1));
    }

    getSubBrandById(subBrandID) {
        return this.http.get(this.apiURL + "/api/v1/contractor/subbrand/"+subBrandID+"/", this.options).pipe(take(1));
    }

    deleteSubBrand(subBrandID) {
        return this.http.delete(this.apiURL + "/api/v1/contractor/subbrand/"+subBrandID+"/", this.options).pipe(take(1));
    }

    saveBrand(brandObj) {
        return this.http.post(this.apiURL + "/api/v1/contractor/brand/", brandObj, this.options).pipe(take(1));
    }

    saveSubBrand(subBrandObj) {
        return this.http.post(this.apiURL + "/api/v1/contractor/subbrand/", subBrandObj, this.options).pipe(take(1));
    }

    updateSubBrand(subBrandID, subBrandObj) {
        return this.http.put(this.apiURL + "/api/v1/contractor/subbrand/"+subBrandID+"/", subBrandObj, this.options).pipe(take(1));
    }
}