import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { TokenComponent } from './views/token/token.component';
import { RegisterComponent } from './views/register/register.component';

import { AuthGuard } from './guards/auth.guard';
import { UserGroups } from './guards/usergroup.model';
import { ApiConfigService } from './services/apiconfig.service';
import { ProfileService } from './services/profile.service';
import { CriteriaService } from './services/criteria.service';
import { ContractorService } from './services/contractor.service';
import { TreeEditService } from './services/tree-edit.service';
import { TreePartnerService } from './services/tree-partner.service';
import { AlertService } from './services/alert.service';
import { TitleService } from './services/title.service';
import { TreeStatsBrandService } from './services/tree-stats-brand.service';
import { TreeStatsCriteriaService } from './services/tree-stats-criteria.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'partners',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'token',
    component: TokenComponent,
    data: {
      title: 'Token Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Domov'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'statistics',
        loadChildren: () => import('./views/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT]
        }
      },
      {
        path: 'criteria',
        loadChildren: () => import('./views/criteria/criteria.module').then(m => m.CriteriaModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT]
        }
      },
      {
        path: 'brands',
        loadChildren: () => import('./views/brands/brands.module').then(m => m.BrandsModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT, UserGroups.REGULAR]
        }
      },
      {
        path: 'partners',
        loadChildren: () => import('./views/partners/partners.module').then(m => m.PartnersModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT, UserGroups.REGULAR]
        }
      },
      {
        path: 'partners-criteria/:id',
        loadChildren: () => import('./views/partners-criteria/partners-criteria.module').then(m => m.PartnersCriteriaModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT]
        }
      },
      {
        path: 'partners-criteria',
        loadChildren: () => import('./views/partners-criteria/partners-criteria.module').then(m => m.PartnersCriteriaModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT]
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR,]
        }
      },
      {
        path: 'history',
        loadChildren: () => import('./views/history/history.module').then(m => m.HistoryModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT]
        }
      },
      {
        path: 'mailing',
        loadChildren: () => import('./views/mailing/mailing.module').then(m => m.MailingModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF]
        }
      },
      {
        path: 'mailingLog',
        loadChildren: () => import('./views/mailing_log/mailing_log.module').then(m => m.MailingLogModule),
        canActivate: [AuthGuard],
        data : {
          userGroups: [UserGroups.ADMIN,]
        }
      },
    ]
  },
  { 
    path: '**', 
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: false }) ],
  exports: [ RouterModule ],
  providers: [ AuthGuard, ProfileService, ApiConfigService, CriteriaService, ContractorService, TreeEditService, TreePartnerService, AlertService, TitleService, TreeStatsBrandService, TreeStatsCriteriaService ]
})
export class AppRoutingModule {}
