import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AlertService {

  public class = 'alert alert-danger';
  public message;
  public code;

  public showSuccess(message, timeout){

    this.class = 'alert alert-success';
    this.code = 0;
    this.message = message;
    
    setTimeout(function(){
      this.class = 'alert';
      this.code = null;
      this.message = null;
     }.bind(this), timeout);
  }
}