import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfigService } from '../../services/apiconfig.service';
import { ProfileService } from '../../services/profile.service';
import { environment, oauth } from '../../../environments/environment';

import { authCodeFlowConfig } from '../../helper/authConfig';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'token.component.html'
})
export class TokenComponent {

  loginUrl;
  loginError;

  constructor(
    private configService: ApiConfigService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private oauthService: OAuthService
  ) {
    //profileService.getCachedPermissions();
    //profileService.getCachedProfile();

    this.loginUrl = environment.loginURL;
    this.oauthService.configure(authCodeFlowConfig);
  }

  ngOnInit() {

    if(oauth.flow == 'code'){

      this.oauthService.loadDiscoveryDocumentAndTryLogin();

      this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(_ => {
        let accessToken = this.oauthService.getAccessToken();
  
        console.log(accessToken);
  
        localStorage.setItem('jwt', accessToken);
  
        this.profileService.getPermissions().subscribe(
          (data) => {
            this.profileService.permissions = (data as any);
            localStorage.setItem('permissions', JSON.stringify(this.profileService.permissions));
          },
          (error) => {
            this.loginError = 'Uporabnik ni vpisan ali nima pravic!';
          }
        );
  
        this.profileService.getProfile().subscribe(
          (data) => {
            this.profileService.info = (data as any);
            this.router.navigateByUrl("partners");
          }
        );
  
      });

    } else {

      this.route.fragment.subscribe(frag => {
        let accessToken = new URLSearchParams(frag).get('access_token');
        this.loginError = new URLSearchParams(frag).get('error_description');
        
        if(accessToken){
          //console.log(accessToken);
          localStorage.setItem('jwt', accessToken);
  
          this.profileService.getProfile().subscribe(
            (data) => {
              this.profileService.permissions = (data as any);
            }
          );
    
          this.profileService.getProfile().subscribe(
            (data) => {
              this.profileService.info = (data as any);
              this.router.navigateByUrl("partners");
            }
          );
  
        }
      });

    }
  }

 }
