import { INavData } from '@coreui/angular/lib/sidebar/public_api';
import { UserGroups } from './guards/usergroup.model';

export const navItems = [
  {
    name: 'Statistika',
    url: '/statistics',
    icon: 'icon-stats',
    userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF]
  },
  {
    name: 'Partnerji',
    url: '/partners',
    icon: 'icon-partners',
    userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT, UserGroups.REGULAR]
  },
  {
    name: 'Kriteriji',
    url: '/partners-criteria',
    icon: 'icon-partners-criteria',
    userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF]
  },
  {
    name: 'Urejanje kriterijev',
    url: '/criteria',
    icon: 'icon-criteria',
    userGroups: [UserGroups.ADMIN,]
  },
  {
    name: 'Znamke',
    url: '/brands',
    icon: 'icon-brands',
    userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR, UserGroups.REGIONCHEF, UserGroups.ASSISTENT, UserGroups.REGULAR]
  },
  {
    name: 'Uporabniki',
    url: '/users',
    icon: 'icon-users',
    userGroups: [UserGroups.ADMIN,]
  },
  {
    name: 'Zgodovina sprememb',
    url: '/history',
    icon: 'icon-history',
    userGroups: [UserGroups.ADMIN,]
  },
  {
    name: 'Zgodovina obveščanja',
    url: '/mailingLog',
    icon: 'icon-mailing',
    userGroups: [UserGroups.ADMIN,]
  },
  {
    name: 'Sporočila',
    url: '/mailing',
    icon: 'icon-mailing',
    userGroups: [UserGroups.ADMIN, UserGroups.DIRECTOR,  UserGroups.REGIONCHEF]
  },
];
