import { Injectable } from '@angular/core';
import { ContractorService } from './contractor.service';

import {FlatTreeControl} from '@angular/cdk/tree';
import {DynamicFlatNode, DynamicDataSource} from '../helper/brand-data-source';
@Injectable({
  // declares that this service should be created
  // by the root application injector.
  providedIn: 'root',
})
export class TreeStatsBrandService {

  public treeControl: FlatTreeControl<DynamicFlatNode>;
  public dataSource: DynamicDataSource;

  constructor(private contractorService: ContractorService) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.contractorService, 'si');
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;
}