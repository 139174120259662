import { CriteriaService } from '../services/criteria.service';
import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
    constructor(public item: string, public level = 1, public expandable = false, public id: Number, public criteria = Array(), public uniqueId = '',
                public isLoading = false, public index = 0, public hidden = false, public sbids = Array()) {}
  }
  
   /**
   * File database, it can build a tree structured Json object from string.
   * Each node in Json object represents a file or a directory. For a file, it has filename and type.
   * For a directory, it has filename and children (a list of files or directories).
   * The input will be a json object string, and the output is a list of `FileNode` with nested
   * structure.
   */
  export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  
    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

    subbrand = 0;
    status = '';
    criteriaStats;
    partner;
    subscribed = false;
  
    get data(): DynamicFlatNode[] { return this.dataChange.value; }
    set data(value: DynamicFlatNode[]) {
      this._treeControl.dataNodes = value;
      this.dataChange.next(value);
    }
  
    constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
                private _service: CriteriaService,
                private lang: string) {}
  
    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {

      if(!this.subscribed){
        this._treeControl.expansionModel.changed.subscribe(change => {
          if ((change as SelectionChange<DynamicFlatNode>).added ||
            (change as SelectionChange<DynamicFlatNode>).removed) {
            this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
          }
        });

        this.subscribed = true;
      }
  
      return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }
  
    disconnect(collectionViewer: CollectionViewer): void {}
  
    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
      if (change.added) {
        change.added.forEach(node => this.toggleNode(node, true));
      }
      if (change.removed) {
        change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
      }
    }
  
    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: DynamicFlatNode, expand: boolean) {
      //const children = this._database.getChildren(node.item);
      const children = [];
      const index = this.data.indexOf(node);
      if (!children || index < 0) { // If no children, or cannot find the node, no op
        return;
      }
  
      node.isLoading = true;
  
      //setTimeout(() => {
        if (expand) {
          let subbrandArr = Array();

          if(this.subbrand){
            subbrandArr.push(this.subbrand);
          }

          this._service.getGroups({parent: node.id.toString(), noparent: false}, subbrandArr, '').subscribe(
            (data) => {
              if((data as any).results.length > 0){
                let nodes = (data as any).results.map(group => new DynamicFlatNode(this.lang == 'en' ? group.name_en : group.name_sl, node.level + 1, true, group.id, group.criteria, group.unique_id));
                this.data.splice(index + 1, 0, ...nodes);
                this.addRowIndex();
                this.dataChange.next(this.data);
              }

              this._service.getGroupById(node.id).subscribe(
                (data) => {
                  if((data as any).criteria.length > 0){                    
                    let critData =  (data as any).criteria;
                    let criterias = critData.map(crit => new DynamicFlatNode(
                                                              this.lang == 'en' ? crit.name_en : crit.name_sl,
                                                              node.level + 1, 
                                                              false, 
                                                              crit.id, 
                                                              Array(), 
                                                              crit.unique_id, 
                                                              false, 
                                                              0, 
                                                              this.checkHidden(crit),
                                                              crit.sbids
                                                              ));

                    this.data.splice(index + 1, 0, ...criterias);
                    this.addRowIndex();
                    this.dataChange.next(this.data);
                  }
                }
              );

              /*
              if(node.criteria.length > 0){
                let criterias = node.criteria.map(crit => new DynamicFlatNode(this.lang == 'sl' ? crit.name_sl : crit.name_en, node.level + 1, false, crit.id, Array(), crit.unique_id));
                this.data.splice(index + 1, 0, ...criterias);
              }
              */
  
              // notify the change
              //this.dataChange.next(this.data);
              node.isLoading = false;
            }
          );
  
          /*
          const nodes = children.map(name =>
            //new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name)));
            new DynamicFlatNode(name, node.level + 1, true));
          this.data.splice(index + 1, 0, ...nodes);
          */
        } else {
          let count = 0;
          for (let i = index + 1; i < this.data.length
            && this.data[i].level > node.level; i++, count++) {}
          this.data.splice(index + 1, count);
          
          // notify the change
          this.dataChange.next(this.data);
          node.isLoading = false;
        }
      //}, 100);
    }

    setSubBrand(subbrandID){
      this.subbrand = subbrandID;

      this.filterHidden();
      this.dataChange.next(this.data);
    }

    setStatus(status, criteriaStats = Array()){
      this.status = status;
      this.criteriaStats = criteriaStats;

      this.filterHidden();
      this.dataChange.next(this.data);
    }

    setPartner(partnerID){
      this.partner = partnerID;
    }

    addRowIndex(){
      this.data.forEach((val, ind)=> {
        this.data[ind].index = ind;
      });
    }

    filterHidden(){

      this.data.forEach(function(crit, index){
        if(!crit.expandable){

         this.data[index].hidden = this.checkHidden(crit);
         
        }
      }.bind(this));

    }

    removeCriteriaNode(nodeID){

      this.data.forEach(function(crit, index){
        if(!crit.expandable){

          if(crit.id == nodeID){
            this.data.splice(index, 1);
          }
         
        }
      }.bind(this));

      this.dataChange.next(this.data);
    }

    checkHidden(crit){
      let hidden_status = this.status != '' ? this.criteriaStats ? this.criteriaStats[crit.id] != this.status : false : false;

      let hidden_subbrand = false;

      if(this.subbrand){

        if(crit.sbids){
          hidden_subbrand = crit.sbids.indexOf(this.subbrand) == -1;
        } else {
          hidden_subbrand = true;
        }

      } else {
        hidden_subbrand = false;
      }

      //let hidden_subbrand =  this.subbrand ? crit.sbids ? crit.sbids.indexOf(this.subbrand) == -1 : true : false;
      return hidden_status || hidden_subbrand;
    }

    swapNode(targetNode, sourceNode){
      const targetIndex = this.data.indexOf(targetNode);
      const sourceIndex = this.data.indexOf(sourceNode);

      this._service.swapCriteria(targetNode.id, sourceNode.id).subscribe(
        (data) => {

          const tempTarget = this.data[targetIndex];
          const tempSource = this.data[sourceIndex];

          //this.data[targetIndex] = this.data[sourceIndex];
          this.data[targetIndex] = new DynamicFlatNode(tempSource.item, tempTarget.level, tempSource.expandable, tempSource.id, tempSource.criteria, tempTarget.uniqueId);

          //this.data[sourceIndex] = tempTarget;
          this.data[sourceIndex] = new DynamicFlatNode(tempTarget.item, tempSource.level, tempTarget.expandable, tempTarget.id, tempTarget.criteria, tempSource.uniqueId);

          //console.log(this.data);
          this.addRowIndex();
          this.dataChange.next(this.data);
        }
      );

    }

    refreshGroup(groupID){
      let foundGroup = this.data.filter(obj => {return obj.id === groupID})

      foundGroup.forEach(obj => {
        if(this._treeControl.isExpanded(obj)){
          this._treeControl.collapse(obj);
          this._treeControl.expand(obj);
        } else {
          this._treeControl.expand(obj);
        }
      })
    }
  }