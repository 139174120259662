import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiConfigService } from './apiconfig.service';
import { Router } from '@angular/router';


interface filterGroups {
    parent: String,
    noparent: Boolean
}

interface filterGroupsStats {
    contractor?: String,
    subbrand?: String
}

interface filterData {
    contractor: Number,
    criteria_type: Number,
    status: String,
    subbrand__brand: String
}

interface filterSubData {
    contractor: Number,
    subcriteria_type__parent: Number,
    status: String,
    subbrand: Number
}

interface filterSubAuditData {
    contractor: Number,
    audit_type__criteria: Number,
    status: String,
    subbrand: Number
}

interface filterSubFile {
    criteria_data__contractor: Number,
    criteria_data: Number
}

interface filterSubAudit {
    contractor: Number,
    subcriteria__parent: Number
}

@Injectable()
export class CriteriaService {
    apiURL;
    options;

    constructor(private http: HttpClient, private apiconfig: ApiConfigService, private router: Router) { 
        this.apiURL = this.apiconfig.getApiURL();
        this.options = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.apiconfig.getAPIToken(),
                'X-CSRFToken': this.apiconfig.getCSRFToken()
                }
            ),
            withCredentials: true
        }
    }

    private addSearchParam(searchText){
        let search = '';

        if(searchText && searchText !== ''){
            search='search='+searchText;
        }

        return search;
    }

    private filterArrayValues(paramName, arrayValues){
        let filter = '';

        if(arrayValues){
            arrayValues.forEach(element => {
                filter += paramName+"="+element+"&";
            });
        }

        return filter;
    }

    private filterObjectFields(objectValues){
        let filter = '';

        if(objectValues){
            let arrKeys = Object.keys(objectValues);

            arrKeys.forEach(element => {
                filter += element+"="+objectValues[element]+"&";
            });
        }

        return filter;
    }

    getGroups(filterObj: filterGroups, subbrands: Array<Number>, searchText: string) {

        let search = this.addSearchParam(searchText);

        let filter = this.filterObjectFields(filterObj);

        //filter = filter + this.filterArrayValues('criteria__subbrands', subbrands);

        return this.http.get(this.apiURL + "/api/v1/criteria/group/?"+filter+search, this.options).pipe(take(1));
    }

    getGroupById(groupId) {

        return this.http.get(this.apiURL + "/api/v1/criteria/group/"+groupId+"/", this.options).pipe(take(1));
    }

    saveGroup(groupObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/group/", groupObj, this.options).pipe(take(1));
    }

    updateGroup(groupID, groupObj) {
        return this.http.put(this.apiURL + "/api/v1/criteria/group/"+groupID+"/", groupObj, this.options).pipe(take(1));
    }

    deleteGroup(groupID) {
        return this.http.delete(this.apiURL + "/api/v1/criteria/group/"+groupID+"/", this.options).pipe(take(1));
    }

    getGroupsStats(filterObj: filterGroupsStats) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/stats/groups/?"+filter, this.options).pipe(take(1));
    }

    getData(filterObj: filterData) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/data/?"+filter, this.options).pipe(take(1));
    }

    getSubData(filterObj: filterSubData) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/sub/data/?"+filter, this.options).pipe(take(1));
    }

    createSubData(dataObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/sub/data/", dataObj, this.options).pipe(take(1));
    }

    updateSubData(subID, dataObj) {
        return this.http.put(this.apiURL + "/api/v1/criteria/sub/data/"+subID+"/", dataObj, this.options).pipe(take(1));
    }

    getSubFile(filterObj: filterSubFile) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/sub/file/?"+filter, this.options).pipe(take(1));
    }

    getSubAudit(filterObj: filterSubAudit) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/audit/type/?"+filter, this.options).pipe(take(1));
    }

    getSubAuditByID(subAuditID) {
        return this.http.get(this.apiURL + "/api/v1/criteria/audit/type/"+subAuditID+"/", this.options).pipe(take(1));
    }

    deleteSubAuditByID(subAuditID) {
        return this.http.delete(this.apiURL + "/api/v1/criteria/audit/type/"+subAuditID+"/", this.options).pipe(take(1));
    }

    editAudit(subAuditID, auditObj) {
        return this.http.put(this.apiURL + "/api/v1/criteria/audit/type/"+subAuditID+"/", auditObj, this.options).pipe(take(1));
    }

    saveAudit(auditObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/audit/type/", auditObj, this.options).pipe(take(1));
    }

    getSubAuditData(filterObj: filterSubAuditData) {
        let filter = this.filterObjectFields(filterObj);

        return this.http.get(this.apiURL + "/api/v1/criteria/audit/data/?"+filter, this.options).pipe(take(1));
    }

    updateSubAuditData(auditID, auditObj) {
        return this.http.put(this.apiURL + "/api/v1/criteria/audit/data/"+auditID+"/", auditObj, this.options).pipe(take(1));
    }

    saveSubAuditData(auditObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/audit/data/", auditObj, this.options).pipe(take(1));
    }

    getStatusList(){
        return this.http.get(this.apiURL + "/api/v1/criteria/status/", this.options).pipe(take(1));        
    }

    getCriteriaStats() {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/criteria/", this.options).pipe(take(1));
    }

    getCriteriaStatsForPartner(partnerID) {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/criteria/?partner="+partnerID, this.options).pipe(take(1));
    }

    getPartnerStats() {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/partner/", this.options).pipe(take(1));
    }

    getPartnerStatsForCriteria(criteriaID) {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/partner/?criteria="+criteriaID, this.options).pipe(take(1));
    }

    getPartnerStatsForSubbrand(subbrandID) {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/partner/?subbrand="+subbrandID, this.options).pipe(take(1));
    }

    getBrandStats() {
        return this.http.get(this.apiURL + "/api/v1/criteria/stats/brand/", this.options).pipe(take(1));
    }

    getRequirementByID(subReqID) {
        return this.http.get(this.apiURL + "/api/v1/criteria/sub/type/"+subReqID+"/", this.options).pipe(take(1));
    }

    deleteRequirementByID(subReqID) {
        return this.http.delete(this.apiURL + "/api/v1/criteria/sub/type/"+subReqID+"/", this.options).pipe(take(1));
    }

    editRequirement(subReqID, reqObj) {
        return this.http.put(this.apiURL + "/api/v1/criteria/sub/type/"+subReqID+"/", reqObj, this.options).pipe(take(1));
    }

    saveRequirement(reqObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/sub/type/", reqObj, this.options).pipe(take(1));
    }

    getTypeById(criteriaId) {

        return this.http.get(this.apiURL + "/api/v1/criteria/type/"+criteriaId+"/", this.options).pipe(take(1));
    }

    searchCriteria(search) {
        return this.http.get(this.apiURL + "/api/v1/criteria/type/?search="+search, this.options).pipe(take(1));
    }

    saveCriteria(criteriaObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/type/", criteriaObj, this.options).pipe(take(1));
    }

    deleteCriteria(criteriaID) {
        return this.http.delete(this.apiURL + "/api/v1/criteria/type/"+criteriaID+"/", this.options).pipe(take(1));
    }

    swapCriteria(targetID, sourceID) {
        return this.http.post(this.apiURL + "/api/v1/criteria/swap/?source="+sourceID+"&target="+targetID, this.options).pipe(take(1));
    }

    saveSubFile(criteriaObj) {
        return this.http.post(this.apiURL + "/api/v1/criteria/sub/file/", criteriaObj, this.options).pipe(take(1));
    }

    deleteSubFile(fileID) {
        return this.http.delete(this.apiURL + "/api/v1/criteria/sub/file/"+fileID+"/",  this.options).pipe(take(1));
    }
}