import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
 } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { retry, catchError, timeout } from 'rxjs/operators';
 import { Injectable, Injector } from '@angular/core';
 import { AlertService } from '../services/alert.service';
 import { Router } from '@angular/router';
 
 @Injectable({ providedIn: 'root' })
 export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);

            const alertObj = this.injector.get(AlertService);
            alertObj.class = 'alert alert-danger';
            alertObj.code = error.status as number;
            alertObj.message = error.error as string;

            if(error.status as number == 403){
              if(error.error.detail as string == 'Invalid access token.'){
                localStorage.removeItem('info');
                localStorage.removeItem('jwt');
                const router = this.injector.get(Router);
                router.navigate(['/login']);
              }
            }
                        
            setTimeout(function(){
             alertObj.class = 'alert';
             alertObj.code = null;
             alertObj.message = null; 
            }, 2000);
          }

          // Return an observable with a user-facing error message.
          return throwError('Something bad happened; please try again later.');
        })
      )
  }
 }