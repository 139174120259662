import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, shareReplay, take } from 'rxjs/operators';
import { throwError, from } from 'rxjs';
import { ApiConfigService } from './apiconfig.service';
import { Router } from '@angular/router';

@Injectable()
export class ProfileService {
    apiURL;
    options;
    permissions;
    info;

    constructor(private http: HttpClient, private apiconfig: ApiConfigService, private router: Router) { 
        this.setOptions();
    }

    private setOptions(){
        this.apiURL = this.apiconfig.getApiURL();
        this.options = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.apiconfig.getAPIToken(),
                'X-CSRFToken': this.apiconfig.getCSRFToken()
                }
            ),
            withCredentials: true
        }
    }

    private addSearchParam(searchText){
        let search = '';

        if(searchText && searchText !== ''){
            search='&search='+searchText;
        }

        return search;
    }

    private filterArrayValues(paramName, arrayValues){
        let filter = '';

        if(arrayValues){
            arrayValues.forEach(element => {
                filter += paramName+"="+element+"&";
            });
        }

        return filter;
    }

    private filterObjectFields(objectValues){
        let filter = '';

        if(objectValues){
            let arrKeys = Object.keys(objectValues);

            arrKeys.forEach(element => {
                filter += element+"="+objectValues[element]+"&";
            });
        }
        
        return filter;
    }

    getPermissions() {
        this.setOptions();
        return this.http.get(this.apiURL + "/api/v1/users/permissions/", this.options).pipe(take(1));
    }

    /*
    getCachedPermissions(){

        if(!this.permissions){
            this.permissions = this.getPermissions().pipe(shareReplay(1));
        }

        return this.permissions;
    }
    */

    getProfile() {
        this.setOptions();
        return this.http.get(this.apiURL + "/api/v1/users/me/", this.options).pipe(take(1));
    }

    /*
    getCachedProfile() {

        if(!this.info){
            this.info = this.getProfile().pipe(shareReplay(1));
        }

        return this.info;
    }
    */

    clearPermissions(){
        this.permissions = null;
    }

    clearProfile(){
        this.info = null;
    }

    hasPermission(perm){
        if(this.permissions){
            return this.permissions.indexOf(perm) != -1;
        } else {
            return false;
        }
    }
}