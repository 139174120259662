import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../services/profile.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private profile: ProfileService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    
    var status;

    if (!this.profile.info) {

      let sessionInfo = localStorage.getItem('info');
      
      if(sessionInfo){
        this.profile.info = JSON.parse(sessionInfo);
      }

      let sessionPerm = localStorage.getItem('permissions');
      
      if(sessionPerm){
        this.profile.permissions = JSON.parse(sessionPerm);
      }
    }
    
    if (!this.profile.info) {

        this.router.navigate(['/login'], {
          queryParams: { returnUrl: state.url }
        });
  
        status = false;

    } else {

        if(this.profile.info.is_superuser){
            status = true;
        } else {

            if(route.data.userGroups && route.data.userGroups.length > 0){
                //const matchedGroups = route.data.userGroups.filter(value => this.profile.info.groups.includes({name: value}));
                const matchedGroups = this.profile.info.groups.filter(value => route.data.userGroups.includes(value.name));

                //console.log(matchedGroups);

                if(matchedGroups.length > 0){
                    status = true;
                } else {
                    status = false;
                }
            } else {
                status = true;
            }
        }

    }

    return status;
  }
}