import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ApiConfigService {

  private APIToken;
  
  //constructor() { 
  constructor(private oauthService: OAuthService) { 
    //this.APIToken = 'ea117f3580be71c4d28705c63719f9e842a2aba3';
  }

  //API URL
  getApiURL(){
    return environment.apiURL;
    //return 'http://web.example.com:7100'; // localhost
    //return 'http://localhost:7100'; // localhost
    //return 'http://porsche.tovarnaidej.com'; // test
  }

  saveAPIToken(token){
    localStorage.setItem('jwt', token);
  }

  getAPIToken(){
    let tokenCodeFlow = this.oauthService.getAccessToken()
    //return localStorage.getItem('jwt');
    return tokenCodeFlow ? tokenCodeFlow : localStorage.getItem('jwt');
  }

  getCSRFToken(){
    return this.getCookie('csrftoken');
  }

  private getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}