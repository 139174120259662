import { Component } from '@angular/core';
import { AlertService } from '../../services/alert.service';


@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})
export class AlertComponent {

  constructor(
    public alertService: AlertService
  ) {

  }

  ngOnInit() {

  }

  getFriendlyMessage(code){
    
    switch(code) {
      case 500:
        return "Interna napaka na API!";
        break;
      case 403:
        return "Ni pravic ali potekla seja!";
        break;
      default:
        return "Napaka "+code+" pri povezovanju na API!";
    }

  }

 }
